import React from "react";
import Newsletter from "./Newsletter";
import Divider from "./Divider";

export default function NewsletterSection({ userId }) {
  return (
    <div className="bg-secondary-color-20 w-full flex flex-col justify-center items-center px-8 pb-4">
      <Divider text="Newsletter" />
      <div className="flex flex-col md:flex-row justify-between max-w-7xl">
        {/* Left Side: Text */}
        <div className="text-left m-4 md:mb-0 md:w-1/2">
          <h2 className="text-2xl font-bold mb-2">
            Bleiben Sie auf dem Laufenden
          </h2>
          <p className="text-lg">
            Abonnieren Sie unseren Newsletter und verpassen Sie keine Updates zu
            unseren neuesten Produkten und Nachrichten!
          </p>
        </div>
        {/* Right Side: Input Form */}
        <div className="w-full md:w-1/2 flex justify-center">
          <Newsletter userId={userId} />
        </div>
      </div>
    </div>
  );
}
