import { useEffect, useState } from "react";
import Hero from "./Hero";
import InvestorPerks from "./InvestorPerks";
import ProductCard from "./product/ProductCard";
import Welcome from "./Welcome";
import Divider from "./Divider";
import MainProduct from "./product/MainProduct";
import { getProducts } from "../api/productApi";
import CookieBanner from "./CookieBanner";
import { Video } from "./Video";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import { ConditionallyRender } from "react-util-kit";
import config from "./chatbot/config";
import ActionProvider from "./chatbot/ActionProvider";
import MessageParser from "./chatbot/MessageParser";
import { MessageOutlined } from "@ant-design/icons";
import { getCookie } from "../utils/cookieUtils";
import { whiteOrBlack } from "../utils/colorUtils";
import { getChatbot } from "../api/chatbotApi";
import NewsletterSection from "./NewsletterSection";

export default function Home({ pageData }) {
  const [mainProduct, setMainProduct] = useState();
  const [zeichnableProducts, setZeichnableProducts] = useState();
  const [notZeichnableProducts, setNotZeichnableProducts] = useState();

  const [showChatbot, toggleChatbot] = useState(true);
  const [welcomeMessage, setWelcomeMessage] = useState("");

  console.log("pageData", pageData);

  useEffect(() => {
    onInit();
    initChatbot();
  }, [pageData]);

  async function onInit() {
    const res = await getProducts();
    setMainProduct(res.mainProduct && res.mainProduct[0]);
    setZeichnableProducts(res.zeichnableProducts);
    setNotZeichnableProducts(res.notZeichnableProducts);
  }
  async function initChatbot() {
    const chatbot = await getChatbot(pageData.id);

    setWelcomeMessage(chatbot.welcomeMessage);
  }

  return (
    <>
      {zeichnableProducts && notZeichnableProducts && (
        <div>
          <CookieBanner />
          <Hero product={mainProduct} pageData={pageData} />
          <Welcome pageData={pageData} />
          {pageData?.videoLink && (
            <>
              <Divider text="Unser Imagevideo" />
              <Video pageData={pageData} />
            </>
          )}
          <Divider text="Aktuelle Anlagen" />
          <div className="mb-4">
            <MainProduct product={mainProduct} flip />
          </div>
          <div className="mt-4 flex justify-center w-full">
            <NewsletterSection userId={pageData.user_id} />
          </div>
          {zeichnableProducts.length > 0 && (
            <>
              {zeichnableProducts.length === 1 ? (
                <MainProduct product={zeichnableProducts[0]} />
              ) : (
                <div className="flex flex-wrap gap-16 mt-8 justify-center mb-8">
                  {zeichnableProducts.map((product) => {
                    return <ProductCard product={product} />;
                  })}
                </div>
              )}
            </>
          )}
          {notZeichnableProducts.filter((product) => product.kind !== "kind12")
            .length > 0 && (
            <>
              <Divider text="In Kürze buchbar" />
              <div className="flex flex-wrap gap-16 mt-8 justify-center mb-8">
                {notZeichnableProducts
                  .filter((product) => product.kind !== "kind12")
                  .map((product) => {
                    return <ProductCard product={product} />;
                  })}
              </div>
            </>
          )}
          {notZeichnableProducts.filter((product) => product.kind === "kind12")
            .length > 0 && (
            <>
              <Divider text="Leistungsbilanz" />
              <div className="flex flex-wrap gap-16 mt-8 justify-center mb-8">
                {notZeichnableProducts
                  .filter((product) => product.kind === "kind12")
                  .map((product) => {
                    return <ProductCard product={product} showLogo />;
                  })}
              </div>
            </>
          )}

          <div className="flex justify-center w-full">
            <InvestorPerks />
          </div>
        </div>
      )}
      {/* Chatbot */}
      {welcomeMessage && (
        <>
          <div className="fixed right-10 bottom-[5.5rem] z-50 justify-center">
            <ConditionallyRender
              ifTrue={showChatbot}
              show={
                <Chatbot
                  config={config}
                  messageParser={MessageParser}
                  actionProvider={ActionProvider}
                />
              }
            />
          </div>
          <button
            style={{
              backgroundColor: getCookie("primaryColor"),
            }}
            className="fixed bottom-4 right-10 z-50 p-4 rounded-full shadow-lg"
            onClick={() => toggleChatbot((prev) => !prev)}
          >
            <MessageOutlined
              style={{
                fontSize: "2rem",
                color: whiteOrBlack(getCookie("primaryColor")),
              }}
            />
          </button>
        </>
      )}
    </>
  );
}
