import axios from "axios";

export async function subscribeNewsletter(userId, email) {
  return axios
    .post(`/newsletter-subscriptions/${userId}/`, {
      email: email,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("Something went wrong " + err);
      return err;
    });
}
