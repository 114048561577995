import React from "react";
import { subscribeNewsletter } from "../api/NewsletterApi";
import { Button, Form, Input, notification } from "antd";
import { whiteOrBlack } from "../utils/colorUtils";
import { getCookie } from "../utils/cookieUtils";

export default function Newsletter({ textColor, userId }) {
  const [form] = Form.useForm();

  const onSubscribe = async ({ email }) => {
    try {
      const res = await subscribeNewsletter( userId, email );

      if (res.status === 201) {
        notification.success({
          message: "Erfolg!",
          description: "Sie haben jetzt unseren Newsletter abonniert.",
        });
        form.resetFields();
      } else {
        const errorMessage =
          res.status === 400
            ? "Ihre E-Mail ist bereits abonniert."
            : "Abonnement fehlgeschlagen.";
        notification.error({
          message: "Fehler!",
          description: errorMessage,
        });
      }
    } catch (error) {
      notification.error({
        message: "Fehler!", 
        description: "Ein unerwarteter Fehler ist aufgetreten.",
      });
    }
  }; 

  return (
    <div className="p-3">
      <Form form={form} onFinish={onSubscribe}>
        <p className={`${textColor} italic`}>
          Abonnieren Sie hier unseren Newsletter!
        </p>
        <div className="flex">
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Bitte geben Sie Ihre E-Mail-Adresse ein!",
              },
              {
                type: "email",
                message: "Bitte geben Sie eine gültige E-Mail-Adresse ein!",
              },
            ]}
          >
            <Input
              placeholder="E-Mail"
              size="large"
              type="email"
              className="!rounded-l-md !w-64"
            />
          </Form.Item>
          <Form.Item noStyle>
            <Button
              size="large"
              htmlType="submit"
              className="bg-secondary-color !rounded-r-md !font-medium"
              style={{
                color: whiteOrBlack(getCookie("secondary-color")),
                border: 0,
              }}
            >
              abonnieren
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
